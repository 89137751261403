import {Modal} from '@mantine/core';
import {BasicButton} from '@packages/ui';
import {useRouter} from 'next/router';
import type {FC} from 'react';
import {LOGIN} from '../../config/constants/routes';
import isAppWebView from '../../config/isAppWebView';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../core/entities/login/constants';
import {getMailAuthLinkWithParamsHooks} from '../services/getMailAuthLinkWithParamsHooks';

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const NoLoginAlertModal: FC<Props> = (props) => {
  const router = useRouter();
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title=""
      size="md"
      padding="md"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="text-center">
        <p className="mb-[20px]">
          会員限定機能です。
          アカウントをお持ちでない方は新規会員登録を行ってください。
        </p>
      </div>
      <div className="flex justify-around">
        <BasicButton
          label="ログイン"
          isPrimary={true}
          className="mx-8"
          onClick={async () => {
            const path = `${LOGIN}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${router.asPath}`;
            if (isAppWebView()) {
              location.href = path;
            } else {
              await router.push(path);
            }
          }}
        />
        <BasicButton
          label="新規登録"
          isPrimary={false}
          className="mx-12"
          onClick={() => {
            location.href = mailAuthLink;
          }}
        />
      </div>
    </Modal>
  );
};
