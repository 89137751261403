import {BasicButton, WhiteBgButton} from '@packages/ui';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {LOGIN} from '../../../../config/constants/routes';
import isAppWebView from '../../../../config/isAppWebView';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../../../core/entities/login/constants';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {getMailAuthLinkWithParamsHooks} from '../../../../shared/services/getMailAuthLinkWithParamsHooks';

export const LogInGuideHeaderForSp: React.FC = () => {
  const SP_TOP_HEADER_LOGO =
    '/next-image/common/logo/sp-top-header-background.png';
  const SP_TOP_HEADER_DESC_LOGO =
    '/next-image/common/logo/sp-top-header-description.png';
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});
  const router = useRouter();

  return (
    <div className="block w-full pc:hidden">
      <div
        id="sp-top-header"
        style={{backgroundImage: `url(${SP_TOP_HEADER_LOGO})`}}
        className="h-[350px]"
      >
        <div className="max-w-[328px] mx-auto">
          <Image
            src={SP_TOP_HEADER_DESC_LOGO}
            className="text-center mx-auto"
            width={328}
            height={152}
            alt="header description"
          />
          <div>
            <BasicButton
              isPrimary={true}
              className="my-32"
              onClick={() => {
                location.href = mailAuthLink;
              }}
            >
              無料会員登録
            </BasicButton>
          </div>
          <WhiteBgButton
            label="ログイン"
            isPrimary={false}
            isLarge={false}
            className="w-full my-32"
            onClick={async () => {
              const path = `${LOGIN}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${router.asPath}`;
              if (isAppWebView()) {
                location.href = path;
              } else {
                await router.push(path);
              }
            }}
          />
        </div>
      </div>
      <div className="text-sm p-4 text-left">
        <span className="mx-16 text-black-2">企業研究を読む</span>
        <CommonLink href="/company/1" className="mr-16 text-link">
          マッキンゼー
        </CommonLink>
        <CommonLink href="/company/21" className="mr-16 text-link">
          ゴールドマン
        </CommonLink>
        <CommonLink href="/company/37" className="text-link">
          三菱商事
        </CommonLink>
      </div>
    </div>
  );
};
