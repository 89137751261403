import type {ColumnCarousel} from '../../../core/entities/columns/schema';

const checkImageUrl = async (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.addEventListener('load', () => {
      resolve(true);
    });
    img.addEventListener('error', () => {
      resolve(false);
    });
    img.src = url;
  });
};

export const validateCarousel = async (
  carousel: ColumnCarousel[],
): Promise<ColumnCarousel[]> => {
  const validated = await Promise.all(
    carousel.map(async (item) => {
      const isValid = await checkImageUrl(item.imageURL);
      return isValid && item.title ? item : null;
    }),
  );

  return validated.filter((item): item is ColumnCarousel => item !== null);
};
