import {Box, Button, Text} from '@mantine/core';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {MdArrowForwardIos} from 'react-icons/md';
import {CALENDAR_BUTTON_IMAGE} from '../../../../../config/constants/img';
import {RECRUITING_INFO} from '../../../../../config/constants/routes';
import isAppWebView from '../../../../../config/isAppWebView';
import type {GetMyCalendarType} from '../../../../../core/entities/calendar/schema';
import {useGetCalendar} from '../../../../../core/repositories/calendarRepository';
import {useAuth} from '../../../../../core/usecases/useAuth';

type Props = Record<string, never>;

type CalendarContextProps = {
  CalendarSchedules: GetMyCalendarType;
};

const DATE_TYPE_START_ENTRY = 1;
const DATE_TYPE_DEADLINE = 2;
const DATE_TYPE_EVENT_START = 3;
const DATE_TYPE_EVENT_OTHER = 9;

const CalendarContext: React.FC<CalendarContextProps> = ({
  CalendarSchedules,
}) => {
  const dayOfTheWeek = (date: string) => {
    const dateObj = new Date(date);
    const dayOfTheWeek = dateObj.getDay();
    const dayOfTheWeekList = ['日', '月', '火', '水', '木', '金', '土'];
    return dayOfTheWeekList[dayOfTheWeek];
  };

  const getTime = (date: string | undefined): Date => {
    if (date) {
      return new Date(date);
    }

    return new Date();
  };

  const getDate = (date: string | undefined): string => {
    const time = getTime(date);
    return `${time.getMonth() + 1}/${time.getDate()}`;
  };

  const getNewTime = (date: string | undefined) => {
    const time = getTime(date);
    return `${time.getHours()}:${time.getMinutes()}`;
  };

  return (
    <ul style={{width: '-webkit-fill-available'}}>
      {CalendarSchedules.Schedules.map((calendarSchedule, index) => {
        return (
          <li
            key={calendarSchedule.eventID}
            className={`mb-2 pl-4 relative ${index ? 'border-t border-gray-300 pt-8' : 'pb-8'}`}
          >
            <a href={calendarSchedule.url}>
              <div className="grid gap-4 grid-cols-3">
                <div
                  className={`grid grid-rows-2 gap-16 w-[90px] h-[30px] border-r-[3px] text-black-1 leading-none ${calendarSchedule.dataType === DATE_TYPE_DEADLINE ? 'border-sns-google-plus-red' : 'border-sns-line-green'} float-left`}
                >
                  <div className="text-[20px]">
                    {getDate(calendarSchedule.start)}
                    <span className="text-[11px] pl-12 text-black-2">
                      {`( ${dayOfTheWeek(calendarSchedule.start)} )`}
                    </span>
                  </div>
                  <div className="text-[11px] pt-[2px]">
                    {calendarSchedule.isAllDay
                      ? ''
                      : getNewTime(calendarSchedule.start)}
                  </div>
                </div>
                <div className="col-span-2 grid grid-rows-2 gap-0">
                  <div className="pl-8 text-[13px] mb-1 font-bold text-black-1 truncate">
                    {calendarSchedule.companyText}
                  </div>
                  <div className="pl-8 text-[14px] text-black-2">
                    {calendarSchedule.typeText + calendarSchedule.dataTypeText}
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const RightSideCalender: React.FC<Props> = () => {
  const {user} = useAuth();
  const {data} = useGetCalendar({});
  const router = useRouter();
  const handleAddSchedule = () => {
    if (isAppWebView()) {
      window.location.href = RECRUITING_INFO;
    } else {
      void router.push(RECRUITING_INFO);
    }
  };

  return (
    <Box className="w-full flex flex-col shadow-card">
      <Box className="flex justify-between bg-primary-1 px-12 py-8 ">
        <Text className="mb-2 font-bold text-white text-base">
          {user?.familyName}さんのスケジュール
        </Text>
        <button
          className="bg-white px-8 py-0 group rounded-4 pc:hover:border-primary-2 pc:hover:ease-in-out pc:hover:duration-300"
          style={{lineHeight: 1}}
          onClick={handleAddSchedule}
        >
          <span
            className="text-xs text-primary pc:group-hover:text-primary-2 pc:hover:ease-in-out pc:hover:duration-300"
            style={{lineHeight: 1}}
          >
            予定を追加
          </span>
        </button>
      </Box>
      <Box>
        <Box className="p-12 flex flex-col gap-y-4 items-start bg-white">
          {data?.ok ? (
            <CalendarContext CalendarSchedules={data?.value} />
          ) : (
            <Image
              src={CALENDAR_BUTTON_IMAGE}
              alt="calendar button"
              width={272}
              height={73}
            />
          )}
        </Box>
        <a href="/schedule">
          <Text className="mb-2 bg-background-2 px-12 py-8 text-primary-2 text-[13px] flex items-center justify-center">
            <MdArrowForwardIos />
            すべての予定をスケジュールで見る
          </Text>
        </a>
      </Box>
    </Box>
  );
};
