import {BasicButton} from '@packages/ui';
import Image from 'next/image';
import {useTopCompanyList} from '../../../../../core/repositories/companyRepository';
import {CommonLink} from '../../../../../shared/components/CommonLink';
import {getMailAuthLinkWithParamsHooks} from '../../../../../shared/services/getMailAuthLinkWithParamsHooks';
import {useMediaQuery} from '../../../../../shared/services/useMediaQuery';
import {MailAuth} from '../../../../register/components/Container/Presenter/MailAuthPresenter';

export const CompanyListForNoLoggedIn: React.FC = () => {
  const {isPC} = useMediaQuery();
  const {data: allCompanies} = useTopCompanyList({
    queryParams: {
      limit: 10,
      is_only_top: true,
    },
  });

  const staticCompanies = [
    {id: 1, companyName: 'マッキンゼー・アンド・カンパニー'},
    {id: 3, companyName: 'ボストンコンサルティンググループ'},
    {id: 13, companyName: '野村総合研究所'},
    {id: 21, companyName: 'ゴールドマン・サックス'},
    {id: 16, companyName: 'モルガン・スタンレー'},
    {id: 26, companyName: 'JPモルガン'},
    {id: 15, companyName: 'バークレイズ'},
    {id: 52, companyName: 'P&Gジャパン'},
    {id: 50, companyName: 'ユニリーバ'},
    {id: 49, companyName: '日本ロレアル'},
    {id: 256, companyName: 'ネスレ日本'},
    {id: 37, companyName: '三菱商事'},
    {id: 36, companyName: '伊藤忠商事'},
    {id: 38, companyName: '三井物産'},
    {id: 81, companyName: '電通'},
    {id: 82, companyName: '博報堂／博報堂DYメディアパートナーズ'},
    {id: 43, companyName: 'Google'},
    {id: 46, companyName: '日本IBM'},
    {id: 783, companyName: '外務省'},
    {id: 233, companyName: '経済産業省'},
    {id: 139, companyName: '国際協力機構（JICA）'},
    {id: 66, companyName: '日本銀行'},
    {id: 58, companyName: '三菱UFJ銀行'},
    {id: 63, companyName: '東京海上日動火災保険'},
    {id: 32, companyName: '野村證券'},
    {id: 77, companyName: '三菱地所'},
    {id: 76, companyName: '三井不動産'},
  ];
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="bg-white mt-12 py-12 pt-32 px-24 pc:w-[1200px]">
      <div className="font-bold text-lg">企業を探す</div>
      <div className="text-sm text-black-2 mt-8">
        トップ企業の本選考・インターン・説明会やセミナーなどの新着情報をまとめてGETできます。
      </div>
      <div className="justify-around my-24 flex-wrap flex">
        {isPC
          ? allCompanies?.ok &&
            allCompanies.value.companies?.slice(0, 9).map((company, index) => {
              return (
                <div key={`company-${index}`} className="mt-4 max-w-[100px]">
                  <CommonLink
                    href={`/company/${company.id}`}
                    className="hover:underline"
                  >
                    <Image
                      src={company.logo_url}
                      alt={company.name}
                      width={100}
                      height={75}
                      className="object-contain block w-[60px] h-[45px] pc:w-[100px] pc:h-[75px] border border-solid"
                    />
                    {isPC && (
                      <span className="ml-4 text-xs text-link-1">
                        {company.name}
                      </span>
                    )}
                  </CommonLink>
                </div>
              );
            })
          : allCompanies?.ok &&
            allCompanies.value.companies?.map((company, index) => {
              return (
                <div key={`company-${index}`} className="mt-4">
                  <CommonLink href={`/company/${company.id}`}>
                    <Image
                      src={company.logo_url}
                      alt={company.name}
                      width={100}
                      height={75}
                      className="object-contain block w-[60px] h-[45px] pc:w-[100px] pc:h-[75px] border border-solid"
                    />
                    {isPC && (
                      <span className="ml-4 text-xs text-link-1">
                        {company.name}
                      </span>
                    )}
                  </CommonLink>
                </div>
              );
            })}
      </div>
      <div className="hidden pc:block bg-black-5 p-12 mb-8">
        <div className="text-black-2">〈掲載企業の例〉</div>
        <div className="flex flex-wrap">
          {staticCompanies.map((company, index) => {
            return (
              <div key={`company-${index}`} className="mt-4 mr-16">
                <CommonLink
                  href={`/company/${company.id}`}
                  className="hover:underline"
                >
                  <span className="text-sm text-link-1 whitespace-nowrap">
                    {company.companyName}
                  </span>
                </CommonLink>
              </div>
            );
          })}
        </div>
      </div>
      <div className="pb-12 hidden pc:block">
        {/* 会員登録ボタン */}
        <BasicButton
          label="無料会員登録してもっと見る"
          isPrimary={true}
          isLarge={true}
          onClick={() => {
            location.href = mailAuthLink;
          }}
          className="w-full"
        />
      </div>
    </div>
  );
};
