import {BasicButton} from '@packages/ui';
import Image from 'next/image';
import {IoMdPersonAdd} from 'react-icons/io';
import {
  MdEmail,
  MdExitToApp,
  MdForum,
  MdInsertDriveFile,
  MdOutlineDomain,
} from 'react-icons/md';
import {routes} from '../../../../config/constants';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {getMailAuthLinkWithParamsHooks} from '../../../../shared/services/getMailAuthLinkWithParamsHooks';

const GAISHISHUKATSU_LOGO =
  '/next-image/common/logo/gaishi_logo_how_it_works.png';
const IOS_LOGO = '/next-image/common/logo/ios.png';
const ANDROID_LOGO = '/next-image/common/logo/android.png';

export const LogInGuideHeaderForPc: React.FC = () => {
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="hidden pc:block w-[1200px] h-[413px] bg-white p-[32px] pc:mt-12">
      <div className="flex items-center">
        <Image
          src={GAISHISHUKATSU_LOGO}
          alt="外資就活ロゴ"
          width={316}
          height={33}
        />
        <span className="mt-12 ml-16 text-[14px]">
          外資就活ドットコムは、プロフェッショナルを目指す学生のための就職活動支援メディアです。
        </span>
      </div>
      <div className="relative flex items-center my-24">
        <div className="flex-grow border-t border-gray-300" />
        <span className="flex-shrink mx-24 text-xl font-semibold">
          会員限定サービス
        </span>
        <div className="flex-grow border-t border-gray-300" />
      </div>
      <div className="flex justify-center">
        <div className="w-[253px] h-[167px] text-center">
          <MdOutlineDomain size="87" className="mx-auto" />
          <div className="font-bold text-lg">厳選企業の募集情報</div>
          <div className="text-black-1">
            <div>外資系・日系トップ企業の最新</div>
            <div>インターン・本選考情報が届く</div>
          </div>
        </div>
        <div className="w-[253px] h-[167px] text-center">
          <MdInsertDriveFile size={87} className="mx-auto" />
          <div className="font-bold text-lg">先輩のES・体験記</div>
          <div className="text-black-1">
            <div>実際のインターンや本選考に</div>
            <div>関する体験レポートが読める</div>
          </div>
        </div>
        <div className="w-[253px] h-[167px] text-center">
          <MdForum size={87} className="mx-auto" />
          <div className="font-bold text-lg">内定者に聞くコミュニティ</div>
          <div className="text-black-1">
            <div>トップ企業内定者や社会人OBに</div>
            <div>就活の疑問・悩みを聞ける</div>
          </div>
        </div>
        <div className="w-[253px] h-[167px] text-center">
          <MdEmail size={87} className="mx-auto" />
          <div className="font-bold text-lg">Premiumスカウト</div>
          <div className="text-black-1">
            <div>厳選した優良企業から、</div>
            <div>会食や面接などのスカウトが届く</div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[50%]">
          <BasicButton
            className="mt-[12px] w-[50%] block"
            children={
              <span className="flex justify-center">
                <IoMdPersonAdd size={16} />
                <span className="ml-8">新規会員登録</span>
              </span>
            }
            isPrimary={true}
            isLarge={false}
            onClick={() => {
              location.href = mailAuthLink;
            }}
          />
        </div>
        <CommonLink
          href={
            'https://itunes.apple.com/jp/app/%E3%83%88%E3%83%83%E3%83%97%E4%BC%81%E6%A5%AD%E3%81%B8%E5%86%85%E5%AE%9A%E3%82%92%E3%82%81%E3%81%96%E3%81%99%E5%B0%B1%E8%81%B7%E6%B4%BB%E5%8B%95%E3%82%A2%E3%83%97%E3%83%AA-%E5%A4%96%E8%B3%87%E5%B0%B1%E6%B4%BB%E3%83%89%E3%83%83%E3%83%88%E3%82%B3%E3%83%A0/id517232947?mt=8'
          }
        >
          <Image
            src={IOS_LOGO}
            height={40}
            width={135}
            alt="Download a Android App"
            className="h-[40px] block mt-12 ml-16"
          />
        </CommonLink>
        <CommonLink
          href={
            'https://play.google.com/store/apps/details?id=com.gaishishukatsu.Gaishishukatsu&hl=ja'
          }
        >
          <Image
            src={ANDROID_LOGO}
            height={40}
            width={135}
            alt="Download a Android App"
            className="h-[40px] block mt-12 ml-16"
          />
        </CommonLink>
        <CommonLink
          href={'/login'}
          className="flex text-link justify-center items-center mt-16 ml-16"
        >
          <MdExitToApp size={16} />
          <span>ログイン</span>
        </CommonLink>
      </div>
    </div>
  );
};
