import Image from 'next/image';
import type {FC} from 'react';
import {
  GTAG_CMD_EVENT,
  GTAG_EVENT_NAMES,
  type GtagLocation,
} from '../../../config/constants/gtag';
import {useGetBannerZ} from '../../../core/repositories/advertisementRepository';

type Props = {
  location: GtagLocation;
};
export const SpHeaderBanner: FC<Props> = ({location}) => {
  const {data} = useGetBannerZ({});

  if (!data?.ok || typeof data.value === 'string' || !data.value.imageURL) {
    return <></>;
  }

  window.gtag(GTAG_CMD_EVENT, GTAG_EVENT_NAMES.IMP_BANNER_Z, {
    banner_z_id: data.value.id,
    location,
  });

  return (
    <a href={data.value.linkURL}>
      <Image
        src={data.value.imageURL}
        alt="header banner"
        height={240}
        width={720}
      />
    </a>
  );
};
