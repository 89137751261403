import {Box} from '@mantine/core';
import {WhiteBgButton} from '@packages/ui';
import Image from 'next/image';
import {parseCookies} from 'nookies';
import {useEffect, useRef} from 'react';
import {GoogleAdBannerName} from '../../../../config/constants/googleAdBanner';
import {KAIGAIDAI_BANNER} from '../../../../config/constants/img';
import {useInfiniteNotifications} from '../../../../core/repositories/notificationsRepository';
import {useUsersMe} from '../../../../core/repositories/usersMeRepository';
import {validateCookie} from '../../../../core/usecases/auth/checkOauthTokenExistence';
import {GoogleAdBanner} from '../../../../shared/components/Banner/GoogleAdBanner';
import {SpTopBottomBanner} from '../../../../shared/components/Banner/SpTopBottomBanner';
import {getTopBottomBannerPosition} from '../../../../shared/services/getTopBottomBannerPosition';
import {useGoogleAdBanner} from '../../../../shared/services/useGoogleAdBanner';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';
import {NotificationCard, type NotificationItem} from './NotificationCard';

type Props = Record<string, unknown>;

// ログイントップ全ての通知一覧
export const NotificationCardListPc: React.FC<Props> = () => {
  const {isPC} = useMediaQuery();
  // DFPバナーのセット
  const googleAdBannerNames = [
    GoogleAdBannerName.A_TOP_PAGE_1,
    GoogleAdBannerName.A_TOP_PAGE_2,
  ];
  const devRef = useRef(null);
  const {setShouldRefetch} = useGoogleAdBanner(googleAdBannerNames);

  const cookies = parseCookies();
  const {isValid} = validateCookie(cookies);
  const {data: usersMe} = useUsersMe({
    config: {enabled: isValid},
  });

  // PC用データ
  const {data, isLoading, fetchNextPage} = useInfiniteNotifications({
    limit: 20,
    forAllUsers: true,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setShouldRefetch(true);
    }
  }, [data, isLoading]);

  return (
    <Box className="mt-24">
      {!isPC && <Box className="border-t border-solid border-gray-200" />}
      {data?.pages.map((page, i) => {
        return page.notifications.map((notification: NotificationItem, j) => {
          return (
            <div key={notification.id}>
              {/* 海外大生に海外大就活バナーを表示する */}
              {i === 0 &&
                j === 0 &&
                isPC &&
                usersMe?.ok &&
                usersMe.value.isForeignUniv && (
                  <button
                    className="bg-white mb-16 flex justify-center items-center"
                    onClick={() => {
                      window.open(
                        'https://event3.gaishishukatsu.com/kaigaidaishukatsu',
                      );
                    }}
                  >
                    <Image
                      width={664}
                      height={192}
                      alt="海外大就活"
                      src={KAIGAIDAI_BANNER}
                    />
                  </button>
                )}
              {i === 0 && j === 2 && isPC && (
                <div className="bg-white p-16 mb-16 flex justify-center items-center">
                  <GoogleAdBanner
                    googleAdBannerName={GoogleAdBannerName.A_TOP_PAGE_1}
                  />
                </div>
              )}
              {i === 0 && j === 6 && isPC && (
                <div className="bg-white p-16 mb-16 flex justify-center items-center">
                  <GoogleAdBanner
                    googleAdBannerName={GoogleAdBannerName.A_TOP_PAGE_2}
                  />
                </div>
              )}
              <NotificationCard notification={notification} />
              {!isPC && (
                <Box
                  className="border-t border-solid border-gray-200"
                  key={notification.id}
                />
              )}
            </div>
          );
        });
      })}
      {isPC &&
      data?.pages &&
      data?.pages?.length > 0 &&
      data?.pages?.[data.pages.length - 1]?.nextPage &&
      data?.pages.at(-1)?.nextPage !== 0 ? (
        <div className="mt-16" ref={devRef}>
          <WhiteBgButton
            label="もっと見る"
            isDisabled={isLoading}
            isPrimary={false}
            isLarge={true}
            onClick={async () => {
              await fetchNextPage();
            }}
            className="w-[80%] pc:w-[100%] mx-auto"
          />
        </div>
      ) : null}
    </Box>
  );
};

export const NotificationCardListSp: React.FC<Props> = () => {
  const {isPC} = useMediaQuery();
  // DFPバナーのセット
  const googleAdBannerNames = [
    GoogleAdBannerName.A_TOP_PAGE_1,
    GoogleAdBannerName.A_TOP_PAGE_2,
  ];
  const {setShouldRefetch} = useGoogleAdBanner(googleAdBannerNames);

  // SP用データ
  const {
    data: dataSp,
    isLoading: isLoadingSp,
    fetchNextPage: fetchNextPageSp,
  } = useInfiniteNotifications({
    limit: 20,
    forAllUsers: true,
    withoutCategories: ['community'],
  });

  useEffect(() => {
    if (dataSp && !isLoadingSp) {
      setShouldRefetch(true);
    }
  }, [dataSp, isLoadingSp]);

  return (
    <Box className="mt-24">
      {!isPC && <Box className="border-t border-solid border-gray-200" />}
      {dataSp?.pages.map((page, i) => {
        return (
          <div key={`page-sp-${i}`}>
            {page.notifications.map((notification: NotificationItem, j) => {
              const bannerPosition = getTopBottomBannerPosition(i * 8 + j);
              return (
                <div key={notification.id}>
                  {bannerPosition && (
                    <div className=" w-full pt-4 px-20 pb-20 flex justify-center">
                      <SpTopBottomBanner type={bannerPosition} />
                    </div>
                  )}
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
      {!isPC &&
        dataSp?.pages &&
        dataSp?.pages.length > 0 &&
        dataSp?.pages?.[dataSp.pages.length - 1]?.nextPage &&
        dataSp?.pages.at(-1)?.nextPage !== 0 && (
          <div className="mt-16">
            <WhiteBgButton
              label="もっと見る"
              isDisabled={isLoadingSp}
              isPrimary={false}
              isLarge={true}
              onClick={async () => {
                await fetchNextPageSp();
              }}
              className="w-[80%] pc:w-[100%] mx-auto"
            />
          </div>
        )}
    </Box>
  );
};
