import {Box, Card, Loader} from '@mantine/core';
import {WhiteBgButton} from '@packages/ui';
import {format, subDays} from 'date-fns';
import {useRouter} from 'next/router';
import type React from 'react';
import {useEffect, useState} from 'react';
import {MdArrowForwardIos, MdArrowLeft, MdArrowRight} from 'react-icons/md';
import {COLOR_BLACK_2} from '../../../config/constants/colorCodes';
import {
  MAX_COMPANY_PER_RANKING,
  TAB_LABELS,
} from '../../../config/constants/followCompanyRanking';
import {COMPANIES} from '../../../config/constants/routes';
import {isReleasedFollowRanking} from '../../../config/unReleasePage';
import {FOLLOW_COMPANY_RANKING_TERMS} from '../../../core/entities/company/constants';
import type {
  FollowRankingListTermType,
  RakingListItemType,
  RankingItemType,
} from '../../../core/entities/company/schema';
import {
  useGetFollowCompanyRanking,
  useGetFollowCompanyRankingList,
} from '../../../core/repositories/companyRepository';
import {formatDateToMonthDay} from '../../services/formatDate';
import {CommonLink} from '../CommonLink';
import {RenderRankingNumber} from './RenderRankingNumber';

type currentRanking = {
  ranking: RakingListItemType[];
  scopeDate: string;
};

export const FollowCompanyRanking: React.FC = () => {
  const [currentTerm, setCurrentTerm] = useState<FollowRankingListTermType>(
    FOLLOW_COMPANY_RANKING_TERMS.daily,
  );

  // フォロー数ランキングリリース後に削除
  const [currentRanking, setCurrentRanking] = useState<currentRanking>({
    ranking: [],
    scopeDate: '',
  });

  // フォロー数ランキングリリース後に削除
  const {data: rankingData, isLoading: isRankingLoading} =
    useGetFollowCompanyRanking({});

  // フォロー数ランキングリリース後に削除
  const setCurrentRankingToDaily = () => {
    if (!rankingData?.ok) return;
    setCurrentRanking({
      ranking: rankingData.value.daily.ranking
        .slice(0, MAX_COMPANY_PER_RANKING)
        .map((item) => {
          return convertToRankingItemDTO(item);
        }),
      scopeDate: formatDateToMonthDay(rankingData.value.daily.end),
    });
    setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.daily);
  };

  // フォロー数ランキングリリース後に削除
  const setCurrentRankingToWeekly = () => {
    if (isReleasedFollowRanking()) {
      setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.weekly);
    } else {
      if (!rankingData?.ok) return;
      setCurrentRanking({
        ranking: rankingData.value.weekly.ranking
          .slice(0, MAX_COMPANY_PER_RANKING)
          .map((item) => {
            return convertToRankingItemDTO(item);
          }),
        scopeDate: `${formatDateToMonthDay(
          rankingData.value.weekly.start,
        )} ~ ${formatDateToMonthDay(rankingData.value.weekly.end)}`,
      });
      setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.weekly);
    }
  };

  // フォロー数ランキングリリース後に削除
  const setCurrentRankingToMonthly = () => {
    if (isReleasedFollowRanking()) {
      setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.monthly);
    } else {
      if (!rankingData?.ok) return;
      setCurrentRanking({
        ranking: rankingData.value.monthly.ranking
          .slice(0, MAX_COMPANY_PER_RANKING)
          .map((item) => {
            return convertToRankingItemDTO(item);
          }),
        scopeDate: `${formatDateToMonthDay(
          rankingData.value.monthly.start,
        )} ~ ${formatDateToMonthDay(rankingData.value.monthly.end)}`,
      });
      setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.monthly);
    }
  };

  // フォロー数ランキングリリース後に削除
  useEffect(() => {
    if (isReleasedFollowRanking()) return;

    if (isRankingLoading || !rankingData?.ok) return;
    setCurrentRankingToDaily();
  }, [rankingData, isRankingLoading]);

  // フォロー数ランキングリリース後に削除
  const changeNextRanking = () => {
    if (!rankingData?.ok) return;
    switch (currentTerm) {
      case FOLLOW_COMPANY_RANKING_TERMS.daily: {
        setCurrentRankingToWeekly();
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.weekly: {
        setCurrentRankingToMonthly();
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.monthly: {
        setCurrentRankingToDaily();
        break;
      }
      default: {
        break;
      }
    }
  };

  // フォロー数ランキングリリース後に削除
  const changePrevRanking = () => {
    if (!rankingData?.ok) return;
    switch (currentTerm) {
      case FOLLOW_COMPANY_RANKING_TERMS.daily: {
        setCurrentRankingToMonthly();
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.weekly: {
        setCurrentRankingToDaily();
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.monthly: {
        setCurrentRankingToWeekly();
        break;
      }
      default: {
        break;
      }
    }
  };

  const {data: rankingList, isLoading: isLoadingRankingList} =
    useGetFollowCompanyRankingList({
      config: {
        enabled: isReleasedFollowRanking(),
      },
      limit: MAX_COMPANY_PER_RANKING,
      term:
        currentTerm === FOLLOW_COMPANY_RANKING_TERMS.all
          ? undefined
          : currentTerm,
    });

  const changePrevTerm = () => {
    switch (currentTerm) {
      case FOLLOW_COMPANY_RANKING_TERMS.daily: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.all);
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.weekly: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.daily);
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.monthly: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.weekly);
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.all: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.monthly);
        break;
      }
      default: {
        break;
      }
    }
  };

  const changeNextTerm = () => {
    switch (currentTerm) {
      case FOLLOW_COMPANY_RANKING_TERMS.daily: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.weekly);
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.weekly: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.monthly);
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.monthly: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.all);
        break;
      }
      case FOLLOW_COMPANY_RANKING_TERMS.all: {
        setCurrentTerm(FOLLOW_COMPANY_RANKING_TERMS.daily);
        break;
      }
      default: {
        break;
      }
    }
  };

  const router = useRouter();
  const currentUrl = decodeURI(router.pathname);

  return (
    <Card className="w-full bg-white px-8 py-16">
      <Box className="max-auto flex flex-col items-center">
        <Box className="flex w-full flex-row items-center justify-between">
          <Box
            onClick={() => {
              if (isReleasedFollowRanking()) {
                changePrevTerm();
              } else {
                changePrevRanking();
              }
            }}
            className="cursor-pointer"
          >
            <MdArrowLeft size={36} />
          </Box>
          <Box className="text-sm  text-black-2">フォロー数ランキング</Box>
          <Box
            onClick={() => {
              if (isReleasedFollowRanking()) {
                changeNextTerm();
              } else {
                changeNextRanking();
              }
            }}
            className="cursor-pointer"
          >
            <MdArrowRight size={36} />
          </Box>
        </Box>
        <Box className="mx-auto text-base font-bold text-black-2">
          {TAB_LABELS[currentTerm]} {getTermDate(currentTerm)}
        </Box>
        {((isReleasedFollowRanking() && isLoadingRankingList) ||
          (!isReleasedFollowRanking() && isRankingLoading)) && (
          <div className="flex justify-center items-center w-full py-64">
            <Loader color="gray" />
          </div>
        )}

        {(isReleasedFollowRanking()
          ? rankingList?.ok
            ? rankingList.value.ranking
            : []
          : currentRanking.ranking
        ).map((item, index) => (
          <CommonLink
            className="flex w-[90%] flex-row items-center border-b-2 px-8"
            href={`${COMPANIES}/${item.company.id}`}
            key={index}
            target="_blank"
          >
            {RenderRankingNumber(item.rank)}
            <Box className="ml-8 w-[55%] text-base text-primary-2">
              {item.company.name}
            </Box>
            <Box className="ml-16 w-[50px] text-right text-base text-black-2">
              +{item.diff}
            </Box>
            <MdArrowForwardIos color={COLOR_BLACK_2} />
          </CommonLink>
        ))}

        {((isReleasedFollowRanking() &&
          rankingList?.ok &&
          rankingList?.value.ranking.length === 0) ||
          (!isReleasedFollowRanking() &&
            currentRanking.ranking.length === 0)) && (
          <Box>
            <Box className="mx-8 my-16 text-base text-black-2">
              フォローされた企業がありません。
            </Box>
            <WhiteBgButton
              className="my-8 w-full"
              onClick={() => {
                location.href = '/mypage/follow_companies';
              }}
              label="フォロー企業を見る"
              isPrimary={false}
              isLarge={false}
            />
          </Box>
        )}
      </Box>
      {currentUrl !== '/company' && currentRanking.ranking.length > 5 && (
        <Box
          className="mt-16 flex w-full cursor-pointer flex-row items-center justify-center text-base text-primary-2"
          onClick={() => {
            location.href = '/company';
          }}
        >
          もっと見る
        </Box>
      )}
    </Card>
  );
};

const convertToRankingItemDTO = (item: RankingItemType): RakingListItemType => {
  return {
    company: {
      id: item.companyID,
      name: item.companyName,
      logoUrl: item.logoURL,
      mainPhotoUrl: item.mainPhotoURL,
      pageType: '',
      foundedYear: 0,
      employeeCount: 0,
      catchPhrase: '',
    },
    rank: item.rank,
    count: item.total,
    diff: item.diff,
    isFollowed: item.isFollowed,
    isHiring: false,
  };
};

const getTermDate = (term: string): string => {
  switch (term) {
    case FOLLOW_COMPANY_RANKING_TERMS.daily:
      return `(${format(new Date(), 'MM/dd')})`;
    case FOLLOW_COMPANY_RANKING_TERMS.weekly:
      return `(${format(subDays(new Date(), 7), 'MM/dd')} ~ ${format(new Date(), 'MM/dd')})`;
    case FOLLOW_COMPANY_RANKING_TERMS.monthly:
      return `(${format(subDays(new Date(), 30), 'MM/dd')} ~ ${format(new Date(), 'MM/dd')})`;
    default:
      return '';
  }
};
