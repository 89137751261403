import {MdWarning} from 'react-icons/md';
import {
  maintenanceEndTime,
  maintenanceStartTime,
} from '../../../config/constants/maintenance';

export const MaintenanceTop = () => {
  const maintenanceStartTimeHours = maintenanceStartTime.getHours();
  const maintenanceEndTimeMonth = maintenanceEndTime.getMonth() + 1;
  const maintenanceEndTimeDate = maintenanceEndTime.getDate();
  const maintenanceEndTimeDay = '日月火水木金土'.charAt(
    maintenanceEndTime.getDay(),
  );
  const maintenanceEndTimeHours = maintenanceEndTime.getHours();

  return (
    <div className="w-[1200px] mx-auto mb-12 py-12 bg-white text-red-500 text-center">
      <MdWarning size={18} className="inline-block relative" />
      <span className="ml-2">
        【メンテナンス】 {maintenanceEndTimeMonth}/{maintenanceEndTimeDate}(
        {maintenanceEndTimeDay})&nbsp;
        {maintenanceStartTimeHours}:00~{maintenanceEndTimeHours}
        :00の間、メンテナンスを実施いたします。この間、サービスが繋がりにくくなる可能性がございます。
        <br />
        ご不便をおかけいたしますが、ご理解のほどよろしくお願いいたします。
      </span>
    </div>
  );
};
