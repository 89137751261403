import {Loader} from '@mantine/core';
import {BasicButton} from '@packages/ui';
import {IoMdPeople} from 'react-icons/io';
import {CommonLink} from '../../../../../shared/components/CommonLink';
import {getMailAuthLinkWithParamsHooks} from '../../../../../shared/services/getMailAuthLinkWithParamsHooks';
import {useMediaQuery} from '../../../../../shared/services/useMediaQuery';
import {useRecruitments} from '../../../usecase/useRecruitments';
import {RecruitmentList} from './RecruitmentList';

export const RecruitmentListForNoLoggedIn: React.FC = () => {
  const {isPC} = useMediaQuery();

  const industries = [
    {id: 1, name: 'コンサル・シンクタンク'},
    {id: 2, name: '外資系金融・証券'},
    {id: 3, name: '日系金融・証券'},
    {id: 5, name: '商社'},
    {id: 4, name: '外資系メーカー・サービス'},
    {id: 8, name: '日系メーカー・サービス'},
    {id: 6, name: '国家公務員・公的機関'},
    {id: 7, name: 'ITサービス'},
    {id: 11, name: 'デベロッパー・建設'},
    {id: 9, name: 'マスコミ・広告'},
  ];

  const recruitingItemTypes = [
    {id: 2, name: '本選考･ジョブ'},
    {id: 1, name: 'インターン'},
    {id: 3, name: '説明会･セミナー･イベント'},
    {id: 6, name: '合同イベント'},
    {id: 7, name: '外資就活主催'},
  ];

  const {
    graduationYearsHots,
    recruitments: data,
    nextGraduationYearRecruitments,
    graduationYearRecruitmentsFiltered,
    nextGraduationYearRecruitmentsFiltered,
    allYearRecruitments,
    isLoadingAllGraduationYearRecruitments,
    isLoadingRecruitments,
    isLoadingNextGraduationYearRecruitments,
  } = useRecruitments();

  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="bg-white pc:w-[1200px] my-8 p-8 pc:my-16 pc:p-32">
      <div className="font-bold text-lg flex items-center">
        <IoMdPeople className="mr-16" size={24} />
        募集を探す
      </div>
      {isPC && (
        <div className="text-sm text-black-2">
          外資系・日系トップ企業約200社を厳選して紹介。気になる企業をフォローして新着情報をキャッチしよう。
        </div>
      )}
      {isPC && (
        <div className="border-t mt-8 py-8 px-8 border-b flex">
          <div className="w-[50%]">
            <div className="font-bold text-black-2">業界から探す</div>
            <div className="grid grid-cols-2 gap-4">
              {industries.map((industry) => {
                return (
                  <CommonLink
                    key={`industry-${industry.id}`}
                    href={`/recruiting_info?industryCategoryIDs=${industry.id}`}
                    className="mt-4 text-sm text-link-1 hover:underline"
                  >
                    {industry.name}
                  </CommonLink>
                );
              })}
            </div>
          </div>
          <div className="w-[25%]">
            <div className="font-bold text-black-2">
              選考・イベントの種類から探す
            </div>
            <div>
              {recruitingItemTypes.map((recruitingItemType) => {
                return (
                  <CommonLink
                    key={`recruitingItemType-${recruitingItemType.id}`}
                    href={`/recruiting_info?typeIDs=${recruitingItemType.id}`}
                    className="mt-8 text-sm text-link-1 hover:underline block"
                  >
                    {recruitingItemType.name}
                  </CommonLink>
                );
              })}
            </div>
          </div>
          <div>
            <div className="font-bold text-black-2">時期から探す</div>
            <div>
              <CommonLink
                href="/recruiting_info?order=recent&allgy=true"
                className="mt-8 text-sm text-link-1 hover:underline block"
              >
                新着順
              </CommonLink>
              <CommonLink
                href="/recruiting_info?order=deadline&allgy=true"
                className="mt-8 text-sm text-link-1 hover:underline block"
              >
                締切日が近い順
              </CommonLink>
              <CommonLink
                href="/recruiting_info?order=eventDate&allgy=true"
                className="mt-8 text-sm text-link-1 hover:underline block"
              >
                開催日が近い順
              </CommonLink>
            </div>
          </div>
        </div>
      )}
      {isPC ? (
        // PC
        <div className="flex">
          {data?.pages[0] && data?.pages[0].recruitments.length > 0 && (
            <div className="w-[50%] border-r mt-8 pr-16">
              <div className="font-bold text-black-2 mt-32">
                {graduationYearsHots?.ok &&
                  graduationYearsHots.value.years.at(-2)}
                卒向けの募集情報
              </div>
              <div>
                <RecruitmentList recruitments={data?.pages[0].recruitments} />
              </div>
              <div className="mt-16">
                <BasicButton
                  isPrimary={true}
                  isLarge={true}
                  label="無料会員登録してもっと見る"
                  onClick={() => {
                    location.href = mailAuthLink;
                  }}
                  className="w-full"
                />
              </div>
            </div>
          )}
          {nextGraduationYearRecruitments?.pages[0] &&
            nextGraduationYearRecruitments?.pages[0].recruitments.length >
              0 && (
              <div className="w-[50%] mt-8 pl-16">
                <div className="font-bold text-black-2  mt-32">
                  {graduationYearsHots?.ok &&
                    graduationYearsHots.value.years.at(-1)}
                  卒向け・学年不問の募集情報
                </div>
                <div>
                  <RecruitmentList
                    recruitments={
                      nextGraduationYearRecruitments?.pages[0].recruitments
                    }
                  />
                </div>
                <div className="mt-16">
                  <BasicButton
                    isPrimary={true}
                    isLarge={true}
                    label="無料会員登録してもっと見る"
                    onClick={() => {
                      location.href = mailAuthLink;
                    }}
                    className="w-full"
                  />
                </div>
              </div>
            )}
        </div>
      ) : (
        // SP
        <div className="mt-8">
          {Array.isArray(graduationYearRecruitmentsFiltered) &&
            graduationYearRecruitmentsFiltered.length > 0 && (
              <>
                <div className="font-bold text-black-2  mt-32 border-b mb-16">
                  {graduationYearsHots?.ok &&
                    graduationYearsHots.value.years.at(-2)}
                  卒向けの募集情報
                </div>
                <RecruitmentList
                  recruitments={graduationYearRecruitmentsFiltered.slice(0, 5)}
                />
              </>
            )}
          {Array.isArray(nextGraduationYearRecruitmentsFiltered) &&
            nextGraduationYearRecruitmentsFiltered.length > 0 && (
              <>
                <div className="font-bold text-black-2  mt-32 border-b mb-16">
                  {graduationYearsHots?.ok &&
                    graduationYearsHots.value.years.at(-1)}
                  卒向けの募集情報
                </div>
                <RecruitmentList
                  recruitments={nextGraduationYearRecruitmentsFiltered.slice(
                    0,
                    5,
                  )}
                />
              </>
            )}
          {
            // 学年不問の募集
            allYearRecruitments && (
              <>
                <div className="font-bold text-black-2  mt-32 border-b mb-16">
                  学年不問の募集情報
                </div>
                <RecruitmentList
                  recruitments={allYearRecruitments.slice(0, 5)}
                />
              </>
            )
          }
          {isLoadingRecruitments ||
            isLoadingAllGraduationYearRecruitments ||
            (isLoadingNextGraduationYearRecruitments && (
              <div className="h-[200px] flex justify-center items-center">
                <Loader color="gray" size="lg" />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
