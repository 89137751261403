import type {FOLLOW_COMPANY_RANKING_TERMS} from '../../core/entities/company/constants';

export const TAB_LABELS = {
  all: '累計',
  daily: 'DAILY',
  weekly: 'WEEKLY',
  monthly: 'MONTHLY',
} as const as Record<keyof typeof FOLLOW_COMPANY_RANKING_TERMS, string>;

export const LOGO_RANKING_GOLD = '/next-image/common/logo/ranking_gold.png';
export const LOGO_RANKING_SILVER = '/next-image/common/logo/ranking_silver.png';
export const LOGO_RANKING_BRONZE = '/next-image/common/logo/ranking_bronze.png';

export const MAX_COMPANY_PER_RANKING = 5;
