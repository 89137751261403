import {Box, Title} from '@mantine/core';
import {baseUrl} from '@packages/client';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import {parseCookies} from 'nookies';
import {MdHome} from 'react-icons/md';
import {TITLE_SUFFIX} from '../../../../config/constants';
import {GoogleAdBannerName} from '../../../../config/constants/googleAdBanner';
import {GTAG_LOCATION} from '../../../../config/constants/gtag';
import {REPORT_ANALYTICS_SEARCH} from '../../../../config/constants/routes';
import {validateCookie} from '../../../../core/usecases/auth/checkOauthTokenExistence';
import {SpHeaderBanner} from '../../../../shared/components/Banner/SpHeaderBanner';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {useGoogleAdBanner} from '../../../../shared/services/useGoogleAdBanner';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';
import {
  NotificationCardListPc,
  NotificationCardListSp,
} from './NotificationCardList';

type Props = Record<string, never>;

export const LoggedInTop: React.FC<Props> = () => {
  const cookies = parseCookies();
  const {isValid} = validateCookie(cookies);

  const {isPC} = useMediaQuery();
  const pageTitle = 'マイページ';
  const canonicalUrl = baseUrl();
  // DFPバナーのセット
  const googleAdBannerNames = [
    GoogleAdBannerName.RICH_DESKTOP_HEADER,
    GoogleAdBannerName.RICH_DESKTOP_BODY,
    GoogleAdBannerName.B_RECTANGLE_BOTTOM,
    GoogleAdBannerName.A_TOP_PAGE_2,
  ];
  useGoogleAdBanner(googleAdBannerNames);

  return (
    <>
      <Head>
        <title key="title">
          {pageTitle}
          {TITLE_SUFFIX}
        </title>
        <meta key="og:title" property="og:title" content={pageTitle} />
        <meta key="og:url" property="og:url" content={canonicalUrl} />
        <meta
          name="description"
          content="外資・日系トップ企業を目指す学生に役立つ就職活動サイト「外資就活ドットコム」。インターン募集、セミナー、説明会、選考会など、外資・日系トップ企業の新卒採用に関する最新情報を随時掲載。"
        />
        <meta
          name="keywords"
          content="外資・日系トップ企業,企業一覧,外資,就活,インターンシップ,新卒採用,会社説明会,就活コラム,ES・体験記,注目企業"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      {!isPC && (
        <>
          {isValid && <SpHeaderBanner location={GTAG_LOCATION.TOP} />}
          <Title
            order={1}
            className="border-t-2 border-primary-1 bg-white p-[11px] text-lg pc:px-32 flex"
          >
            <MdHome className="text-primary-1 text-xl" />
            <div className="flex pl-8 justify-between items-center text-base text-black-1">
              マイページ
            </div>
          </Title>
          <div className="grid grid-cols-3 h-36">
            <div className="text-center text-primary-1 p-4">
              <a href="/">新着</a>
            </div>
            <div className="text-center text-black-2 bg-black-5 p-4">
              <CommonLink href="/mypage/favs/companies">フォロー</CommonLink>
            </div>
            <div className="text-center text-black-2 bg-black-5 p-4">
              <CommonLink href="/clip/reports">クリップ</CommonLink>
            </div>
          </div>
          <Box>
            <div className="flex mt-12 w-full justify-center p-8">
              <CommonLink
                className={
                  'py-4 px-4 rounded-l bg-black-2 text-white w-full text-center border-black-2'
                }
                href="/"
              >
                すべて
              </CommonLink>
              <CommonLink
                className={
                  'py-4 px-4 rounded-r bg-white text-black-2 border border-solid border-black-2 w-full text-center'
                }
                href="/following/company"
              >
                フォロー企業
              </CommonLink>
            </div>
          </Box>
          {/* AI選考対策誘導フロートバナー */}
          <Box
            id="logined_top_float_banner"
            className="fixed bottom-8 flex justify-center items-center"
          >
            <Link href={REPORT_ANALYTICS_SEARCH}>
              <Image
                src={'/next-image/reportAnalytics/float_banner.png'}
                alt={'float_banner'}
                width={370}
                height={50}
              />
            </Link>
          </Box>
        </>
      )}
      {isPC ? <NotificationCardListPc /> : <NotificationCardListSp />}
    </>
  );
};
