export const SMALL_CAPITAL_LENGTH_LIMIT = 30;
export const MEDIUM_CAPITAL_LENGTH_LIMIT = 7;
export const LARGE_CAPITAL_LENGTH_LIMIT = 20;
export const TINY_CAPITAL_LENGTH_LIMIT = 30;

export enum CompanyCardType {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  TINY = 'tiny',
  ARCHIVE = 'archive',
}

// SPWebの画面サイズによって変わる 契約プランによって変わるCompanyCardTypeとは別
export enum SpWindowSize {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 2,
}

// 企業名の文字数制限
export const COMPANY_NAME_LIMITS = {
  [CompanyCardType.LARGE]: {
    [SpWindowSize.SMALL]: 20,
    [SpWindowSize.MEDIUM]: 25,
    [SpWindowSize.LARGE]: 31,
  },
  [CompanyCardType.MEDIUM]: {
    [SpWindowSize.SMALL]: 13,
    [SpWindowSize.MEDIUM]: 21,
    [SpWindowSize.LARGE]: 25,
  },
  [CompanyCardType.SMALL]: {
    [SpWindowSize.SMALL]: 17,
    [SpWindowSize.MEDIUM]: 21,
    [SpWindowSize.LARGE]: 23,
  },
  [CompanyCardType.TINY]: {
    [SpWindowSize.SMALL]: 17,
    [SpWindowSize.MEDIUM]: 21,
    [SpWindowSize.LARGE]: 23,
  },
  [CompanyCardType.ARCHIVE]: {
    [SpWindowSize.SMALL]: 17,
    [SpWindowSize.MEDIUM]: 21,
    [SpWindowSize.LARGE]: 23,
  },
};

// 「新着情報」の文字が付いている時の企業名の文字数制限
export const COMPANY_NAME_LIMITS_WITH_NEW = {
  [CompanyCardType.LARGE]: {
    [SpWindowSize.SMALL]: 17,
    [SpWindowSize.MEDIUM]: 22,
    [SpWindowSize.LARGE]: 28,
  },
  [CompanyCardType.MEDIUM]: {
    [SpWindowSize.SMALL]: 10,
    [SpWindowSize.MEDIUM]: 18,
    [SpWindowSize.LARGE]: 22,
  },
  [CompanyCardType.ARCHIVE]: {
    [SpWindowSize.SMALL]: 14,
    [SpWindowSize.MEDIUM]: 17,
    [SpWindowSize.LARGE]: 20,
  },
};

// 業界名の文字数制限
export const INDUSTRY_NAME_LIMITS = {
  [CompanyCardType.LARGE]: {
    [SpWindowSize.SMALL]: 13,
    [SpWindowSize.MEDIUM]: 7,
    [SpWindowSize.LARGE]: 11,
  },
  [CompanyCardType.MEDIUM]: {
    [SpWindowSize.SMALL]: 8,
    [SpWindowSize.MEDIUM]: 13,
    [SpWindowSize.LARGE]: 16,
  },
  [CompanyCardType.SMALL]: {
    [SpWindowSize.SMALL]: 11,
    [SpWindowSize.MEDIUM]: 13,
    [SpWindowSize.LARGE]: 15,
  },
  [CompanyCardType.TINY]: {
    [SpWindowSize.SMALL]: 11,
    [SpWindowSize.MEDIUM]: 13,
    [SpWindowSize.LARGE]: 15,
  },
  [CompanyCardType.ARCHIVE]: {
    [SpWindowSize.SMALL]: 20,
    [SpWindowSize.MEDIUM]: 25,
    [SpWindowSize.LARGE]: 28,
  },
};
export const TAB_LIST = [
  {label: '企業研究', value: 'company_info'},
  {label: '募集情報', value: 'recruitment'},
  {label: 'ES・体験記', value: 'report'},
];

// 内定者属性(OfferedAttribute)を非表示にする企業ID(特別対応)
export const HIDE_OFFERED_ATTRIBUTE_COMPANY_IDS = [
  9 /** CDI */, 2887 /** マース・アンド・コー・コンサルティング・ジャパン */,
  11_536 /** AAIC */, 23298 /** キャプラ・インベストメント・ジャパン */,
];

export const CONSULTANT_INDUSTRIES = [
  {
    ids: [1],
    name: '戦略コンサル',
  },
  {
    ids: [34],
    name: '総合コンサル',
  },
  {
    ids: [35],
    name: 'ITコンサル',
  },
  {
    ids: [38],
    name: 'シンクタンク',
  },
  {
    ids: [54, 55],
    name: '財務・会計・その他コンサル',
  },
  {
    ids: [40],
    name: 'M&Aアドバイザリー',
  },
];

export const FOLLOW_COMPANY_RANKING_TERMS = {
  all: 'all',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export const FOLLOW_COMPANY_RANKING_TERM_LABELS = {
  all: '累計',
  daily: '急上昇',
  weekly: '週間',
  monthly: '月間',
} as const as Record<keyof typeof FOLLOW_COMPANY_RANKING_TERMS, string>;
