import Image from 'next/image';
import type {FC} from 'react';
import {GTAG_CMD_EVENT, GTAG_EVENT_NAMES} from '../../../config/constants/gtag';
import {useGetBanner} from '../../../core/repositories/advertisementRepository';

type Props = {
  type?: 'top' | 'bottom';
  width?: number;
};

export const SpTopBottomBanner: FC<Props> = ({type, width}) => {
  const {data} = useGetBanner({});

  if (type === undefined || !data?.ok || !data.value[type].imageUrl) {
    return <></>;
  }

  if (type === 'top' && window.gtag) {
    window.gtag(GTAG_CMD_EVENT, GTAG_EVENT_NAMES.IMP_TOP_BANNER, {
      banner_type: type,
      ad_type: data.value[type].adType,
      ad_id: data.value[type].adId,
    });
  } else if (type === 'bottom' && window.gtag) {
    window.gtag(GTAG_CMD_EVENT, GTAG_EVENT_NAMES.IMP_BOTTOM_BANNER, {
      banner_type: type,
      ad_type: data.value[type].adType,
      ad_id: data.value[type].adId,
    });
  }

  return (
    <a href={data.value[type].linkUrl}>
      <Image
        src={data.value[type].imageUrl}
        alt={`${type} banner`}
        height={width ? (width * 50) / 320 : 50}
        width={width ?? 320}
      />
    </a>
  );
};
