import type {
  GetCompanyListResponse,
  GetEngineerCompanyListResponse,
  ResultGetCompanyList,
  ResultGetEngineerCompanyList,
} from '../../repositories/companyRepository';

export function getCompaniesFromPages(
  data: ResultGetCompanyList | undefined,
): GetCompanyListResponse | undefined {
  if (!data?.ok) return undefined;
  return data.value;
}

export function getEngineerCompaniesFromPages(
  data: ResultGetEngineerCompanyList | undefined,
): GetEngineerCompanyListResponse | undefined {
  if (!data?.ok) return undefined;
  return data.value;
}
